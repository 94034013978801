import React from "react"

import Layout from "../components/layout"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { Col, Container, Row } from "react-bootstrap"
import Meta from "../components/meta"

export default ({ data }) => (
  <Layout>
    <Meta title={"Über meine Person"}
          description={"Dorakami Coaching unterstützt die Unternehmensentwicklung mittels klarer Kommunikation, Resilienz & Achtsamkeit basierend auf langjähriger Erfahrung."}/>
    <Container className={"section person-page"}>
      <h1>Hallo, ich bin Christoph Berndt</h1>
      <Row>
        <Col className={"portrait-image-column"} xl={3} lg={3} sm={12}>
          <Img fluid={data.portraitImage.childImageSharp.fluid} className={"image-wrapper portrait-image"}/>
        </Col>
        <Col className={"portrait-text-column"} xl={9} lg={8} sm={12}>
          <div className={"portrait-text"}>
            <p>
              Aus meiner Lebenserfahrung von mehr als 50 Jahren, Führungserfahrung von über 25 Jahren sowie
              aller Fehler, die ich gemacht und aus denen ich viel gelernt habe, ist
              die Vision gewachsen, meine gesamte Expertise zusammenzufassen. Mithilfe dieser biete ich interessierten
              Menschen, Organisationen und Unternehmen meine Unterstützung an.
            </p>
            <p>
              Neben meiner beruflichen Entwicklung war und ist mir wichtig, meine Kenntnisse und Fähigkeiten auch in
              angrenzenden Bereichen weiter zu entwickeln um meinen Horizont stets zu erweitern. Somit schaffe ich mir
              die Möglichkeit, themenübergreifend, umfassend und vorausschauend zu agieren.
            </p>
            <p>
              Als meine Mission sehe ich dabei an, Menschen in den ihnen zur Verfügung stehenden Möglichkeiten zu
              unterstützen, denn jedes Individuum trägt bereits alle benötigten Anlagen in sich. Ebenso ist es mein
              Anliegen, das Miteinander in Organisationen positiver zu gestalten.
            </p>
          </div>
        </Col>
      </Row>

      <div className={"sub-section"}>
        <h2>Beruflicher Werdegang</h2>
        <Row>
          <Col md={6}>
            <p>Meine Führungserfahrung von mehr als 25 Jahren habe ich mit in folgenden Positionen mit
              Führungsverantwortung angeeignet:</p>
            <ul className={"bullet-list"}>
              <li>
                1995 – 1997:<br/>
                Abteilungsleiter der Konstruktionsabteilung in einem mittelständischen Maschinenbauunternehmen
              </li>
              <li>
                1997 – 2013:<br/>
                Leitender Angestellter der EBB Entgratungs- und Bearbeitungstechnik Berndt
              </li>
              <li>
                2013 – heute:<br/>
                Geschäftsführender Gesellschafter der EBB Bearbeitungstechnik Berndt GmbH & Co. KG
              </li>
            </ul>
          </Col>
          <Col md={6}>
            <p>Als Grundlage dafür dient meine ursprüngliche Berufsausbildung, welche ich durch untenstehende weitere
              Qualifikationen stets erweitert habe:</p>
            <ul className={"bullet-list"}>
              <li>Betriebsschlosser</li>
              <li>Technischer Zeichner</li>
              <li>
                Staatlich geprüfter Maschinenbautechniker, Fachrichtung Fertigungstechnik<br/>
                (Fachschule für Technik, Arnsberg)
              </li>
              <li>
                Technischer Betriebswirt HWO<br/>
                (Institut für technische Betriebsführung, Karlsruhe)
              </li>
            </ul>
          </Col>
        </Row>
      </div>

      <Row>
        <Col md={6} className={"sub-section"}>
          <h2>Coachingbezogene Qualifikationen</h2>
          <ul className={"bullet-list"}>
            <li>Meistergrad im Karate Dô, 2. Dan</li>
            <li>NLP Practitioner (DVNLP)</li>
            <li>NLP Master (DVNLP)</li>
            <li>NLP Trainer (DVNLP)</li>
            <li>NLP Master Coach (DVNLP)</li>
            <li>Mentaltrainer</li>
            <li>Hypnosecoach</li>
          </ul>
          <p>Die Methoden des neurolinguistischen Programmierens (NLP) dienen für mein Verständnis ausschließlich der
            Persönlichkeitsentwicklung.</p>
        </Col>
        <Col md={6} className={"sub-section"}>
          <h2>Weitere Qualifikationen im beruflichen Kontext</h2>
          <ul className={"bullet-list"}>
            <li>Bonitätsanalyst (IHK)</li>
            <li>Projektmanagement (IHK)</li>
            <li>Key-Account Manager (IHK)</li>
            <li>Vertriebsleiter (IHK)</li>
            <li>Liquiditäts- und Ergebnisrechnung (IHK)</li>
          </ul>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export const query = graphql`
  query {
    portraitImage: file(relativePath: { eq: "christoph-berndt.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 370, maxHeight: 504, quality: 90, srcSetBreakpoints: [370]) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
